import { lazy } from "react";
import { getUserToken } from "../api/wrapper.js";

const isLoggedIn = getUserToken();

const PublicRoutes = [
  {
    path: "*",
    component: lazy(() => import("../pages/404/index.js")),
    exact: true,
    show: true,
  },
  {
    path: "/login",
    component: lazy(() => import("../pages/Login/index.jsx")),
    exact: true,
    show: !isLoggedIn, // Hide the login route if user is logged in
  },
];

const PrivateRoutes = [
  {
    path: "/",
    component: lazy(() => import("../pages/Dashboard/index.jsx")),
    exact: true,
  },

  // users
  {
    path: "subscribed",
    component: lazy(() => import("../pages/Users/SubscribedUsers/index.jsx")),
    exact: true,
  },
  {
    path: "free",
    component: lazy(() => import("../pages/Users/FreeUsers/index.jsx")),
    exact: true,
  },

  // visitors
  {
    path: "new-visitors",
    component: lazy(() => import("../pages/Visitors/NewUsers/index.jsx")),
    exact: true,
  },
  {
    path: "all-visitors",
    component: lazy(() => import("../pages/Visitors/AllUsers/index.jsx")),
    exact: true,
  },
  {
    path: "customer-support",
    component: lazy(() => import("../pages/CustomerSupport/index.jsx")),
    exact: true,
  },
  {
    path: "payments",
    component: lazy(() => import("../pages/Payments/index.jsx")),
    exact: true,
  },
];

export { PublicRoutes, PrivateRoutes };
