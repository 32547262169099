import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Suspense } from "react";
import { PrivateRoutes, PublicRoutes } from "./routes";
import { getUserToken } from "./api/wrapper";
import "./App.scss";

function App() {
  const isLoggedIn = getUserToken();
  return (
    <Suspense fallback={`Loading....`}>
      <BrowserRouter>
        <Routes>
          {/* Public routes */}
          {PublicRoutes.map(({ path, exact, show, component: Component }) => (
            <Route
              key={path}
              exact={exact}
              path={path}
              element={show ? <Component /> : <Navigate to="/" />}
            />
          ))}
          {/* Private routes */}
          {PrivateRoutes.map(({ path, exact, component: Component }) => (
            <Route
              key={path}
              exact={exact}
              path={path}
              element={isLoggedIn ? <Component /> : <Navigate to="/login" />}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
